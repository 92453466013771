import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";


// core components
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
function Index() {

  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="wrapper" id="index">
        <AboutUsHeader />
        <div className="section">
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">1HP tokens</h2>
                <h4 className="description" style={{color:"black"}}>
                  Official Token Policy ID: 4b70a300d92bc7d7fbdd6d195fd343dc5bbe91f8f4849d2a8515e60b
                </h4>
                <h4 className="description" style={{color:"black"}}>
                We are currently building a blockchain ecosystem where token holders can participate and 
                the community can grow naturally. Check back here for updates on Token Holder Rewards,
                Tokenomics, and Release Date.
                  </h4>
              </Col>
              <Col>
              <img
              alt={"..."}
              style={{width: "70%"}}
              src={require("assets/img/myimages/token1.png").default}
            ></img>
              </Col>

            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/myimages/Adventure1.png").default + ")"
                    }}
                  >
                    <p className="blockquote blockquote-primary" style={{color:"red"}}>
                      <strong>
                      "Gaming is one of those things that's pretty amazing because when 
                      you think about it, everybody wants to game." 
                      
                      <br></br>
                      <br></br>
                      <small>-Lisa Su</small>
                      </strong>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/myimages/Shooter.png").default + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/myimages/Platformer.png").default + ")"
                    }}
                  ></div>
                  <h2>
                    Games
                  </h2>
                  <p style={{fontSize:"20px",color:"black"}}>
                  We strive to provide new and entertaining concepts to the gaming industry 
                with a focus on casual everyday games for a varietry of platforms including
                Android, iOS, Windows, Mac, and many others.  We want to utilize 
                the new opportunities and technologies available to show that small development 
                teams can also build fully scalable games in a short amount of time. 
                    <br/>
                    <br/>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/*
        <div className="section section-team text-center">
          <Container id="team">
            <h2 className="title">Meet Our Team</h2>
            <div className="team">
              <Row>
                <Col></Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                    alt={"..."}
                      style={{width: "50%"}}
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/myimages/Corey.jpg").default}
                    ></img>
                    <h4 className="title">Corey Chang</h4>
                    <p className="category text-info">Developer</p>
                    <p className="description" style={{color:"black"}}>
                      Corey is Developer from Hawaii with a background in Electrical Engineering.
                      He has a passion for video games and left his job as an Electrical
                      Engineer to bring his own ideas to life.{" "}
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                    alt={"..."}
                      style={{width: "50%"}}
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/myimages/Yuki.jpg").default}
                    ></img>
                    <h4 className="title">Christopher Manloloyo</h4>
                    <p className="category text-info">Developer</p>
                    <p className="description" style={{color:"black"}}>
                      Chris has a background in Electrical Engineering. He wants
                      to use his skills to make fun and simple games. You will always catch 
                      him tinkering around with electronics hardware and software.{" "}
                    </p>
                  </div>
                </Col>
                <Col></Col>

              </Row>

            </div>
          </Container>

          <Container id="support">
          <h2 className="title">Support Us</h2>
            <h5> 
            Your gift will help us support us in our goal to create more local developer jobs. 
            Giving is very simple and 100% of your gift will instantly be used towards our work 
            and creating local developer jobs. We are loyal to our contributors which means we will 
            never share your private information with any Third parties and we will not harass you with 
            emails or calls.
            </h5>
            <br/>
            <h5>To support us, you can click on the give button below to or you can <strong>Venmo</strong> us driectly <strong>@HTechStudio</strong></h5>
            <div>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="NKG6NSANNNDLJ" />
              <input type="image" style={{width:"50%"}} src="https://dl.dropbox.com/s/ppv25plqcbz2r71/whitegivebutton.png?dl=0" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
            
            </div>
            
          </Container>

        </div>
                  */}
      </div>
    </>
  );
}

export default Index;
