import React, {useEffect} from "react";
import Unity, { UnityContext } from "react-unity-webgl";

const unityContextShooter1 = new UnityContext({
    loaderUrl: "../../Games/SpaceShooter1/Build/ShootEmUp.loader.js",
    dataUrl: "../../Games/SpaceShooter1/Build/ShootEmUp.data",
    frameworkUrl: "../../Games/SpaceShooter1/Build/ShootEmUp.framework.js",
    codeUrl: "../../Games/SpaceShooter1/Build/ShootEmUp.wasm",
});
const unityContextShooter2 = new UnityContext({
    loaderUrl: "../../Games/SpaceShooter2/Build/Shooter2.loader.js",
    dataUrl: "../../Games/SpaceShooter2/Build/Shooter2.data",
    frameworkUrl: "../../Games/SpaceShooter2/Build/Shooter2.framework.js",
    codeUrl: "../../Games/SpaceShooter2/Build/Shooter2.wasm",
});

function Shooter1() {
    return( 
        <Unity 
        unityContext={unityContextShooter1} 
        style={{ 
            height: 675,
            width: 450,
        }}
        />
    );
}
function Shooter2() {
    return( 
        <Unity 
        unityContext={unityContextShooter2} 
        style={{ 
            height: 450,
            width: 720,
        }}
        />
    );
}


export {Shooter1, Shooter2};