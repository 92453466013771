import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

// core components

function AboutUsHeader() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      {/* old image "assets/img/bg14.jpg" */}
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/myimages/Background3.png").default +
              ")",
          }}
          ref={pageHeader}
        ></div>
        {/*
        <div className="content-center">
          <Row>
            <Col>
              <img
                alt={"..."}
                style={{ width: "10%" }}
                src={require("assets/img/myimages/1hplogo.png").default}
              ></img>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              
              <h1 className="title">1HP Games</h1>
              <h5>
                Updates: <br />
                <ul>
                  <li>New Blockchain Project in Progress. Token Policy ID: 4b70a300d92bc7d7fbdd6d195fd343dc5bbe91f8f4849d2a8515e60b</li>
                  <br/>
                  <li>
                    We are currently phasing out our legacy games to make room
                    for our newer games
                  </li>
                </ul>
              </h5>
      
            </Col>
          </Row>
        </div>
          */}
      </div>
    </>
  );
}

export default AboutUsHeader;
