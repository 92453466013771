import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Muted from "components/Typography/Muted.js";

// core components
import TopNavBar from "components/Navbars/FixedColorNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

//import games and demos
import { Shooter1, Shooter2 } from "views/games/webgames.js";


import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";
import { FormHelperText } from "@material-ui/core";
const useStyles = makeStyles(sectionInterestedStyle);




function Games() {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      window.removeEventListener('resize', handleWindowSizeChange);
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  const isMobile  = (width <= 768);

  // for shooter 1
  const buttonShooter1 = (isMobile? "Desktop Demo Only":"Play Demo");
  const [modalShoot1, setModalShoot1] = useState(false);
  const toggleShoot1 = () => setModalShoot1(!modalShoot1);

  // for shooter 2
  const buttonShooter2 = (isMobile? "Desktop Demo Only":"Play Demo");;
  const [modalShoot2, setModalShoot2] = useState(false);
  const toggleShoot2 = () => setModalShoot2(!modalShoot2);

  return (
    <>
      <div className="wrapper" >
        <div className="section">

          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center title">
                  Some of our Games <br></br>
                </h2>
                <div className="space-50"></div>
              </Col>
            </Row>
            <Row>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Card plain blog>
                    <CardHeader image plain>
                      <iframe src="https://drive.google.com/file/d/127csPSNrLHdd0A3T1ANdPuS3PRwlBJCP/preview" allow="fullscreen"></iframe>
                      <Muted>
                        <h3>Platformer - Unamed</h3>
                      </Muted><br />
                      <Button color="danger" disabled>Demo Unavailable</Button>
                    </CardHeader>
                    <CardBody plain>
                      <h5 >
                        A 2D Platformer Game about a Heroine trying to rescue her Friend.
                      </h5>
                    </CardBody>
                  </Card>
                </GridItem>
                <br />
                <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <iframe src="https://drive.google.com/file/d/1H547L6TnvrL4Pq5XpahaS3DgapMV93IO/preview" allow="fullscreen"></iframe>
                      <Muted>
                        <h3>3D Adventure - Unamed</h3>
                      </Muted><br />
                      <Button color="danger" disabled>Demo Unavailable</Button>
                    </CardHeader>
                    <CardBody plain>

                      <h5>
                        A 3D adventure game where a sword boy and archer girl have to work together to finish their mission.
                      </h5>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <iframe src="https://drive.google.com/file/d/1kVNnm4QxTHM_jogvyBBghxUfe8fn3XQq/preview" allow="fullscreen"></iframe>
                      <Muted>
                        <h3>Space Shooter 1</h3>
                      </Muted><br />
                      <Button color="danger" onClick={toggleShoot1} disabled={isMobile}>{buttonShooter1}</Button>
                      <Modal isOpen={modalShoot1} toggle={toggleShoot1} size="lg">
                        <ModalHeader toggle={toggleShoot1}>
                          Space Shooter 1
                          <br />Controls: Movement - WASD or Arrows Fire/Select - Space
                        </ModalHeader>
                        <ModalBody>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <Shooter1 />
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <p></p>
                          {' '}
                          <Button color="danger" onClick={toggleShoot1}>Exit</Button>
                        </ModalFooter>
                      </Modal>

                    </CardHeader>

                    <CardBody plain>
                      <h5>
                        A basic space shooter (vertical style). You only get 1 life and we had to cheat in the video.
                      </h5>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <iframe src="https://drive.google.com/file/d/1NcSzbT1myZ69DRJfuZd_-le8PAR9KpoV/preview" allow="fullscreen"></iframe>
                      <Muted>
                        <h3>Space Shooter 2</h3>
                      </Muted><br />
                      <Button color="danger" onClick={toggleShoot2}disabled={isMobile}>{buttonShooter1}</Button>
                      <Modal isOpen={modalShoot2} toggle={toggleShoot2} size="lg">
                        <ModalHeader toggle={toggleShoot2}>
                          Space Shooter 2
                          <br />Controls: Movement - WASD or Arrows,
                          Fire/Select - left mouse, Nuke - right mouse
                        </ModalHeader>
                        <ModalBody>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <Shooter2 />
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <p></p>
                          {' '}
                          <Button color="danger" onClick={toggleShoot2}>Exit</Button>
                        </ModalFooter>
                      </Modal>
                    </CardHeader>
                    <CardBody plain>

                      <h5>
                        Another fun Space Shooter because ... why not!? This time it's horizontal with 3 different bosses.
                      </h5>

                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <iframe src="https://drive.google.com/file/d/1sEOa9jtGT34XFxJxI5JS5u6UfEEezeQV/preview" allow="fullscreen"></iframe>
                      <Muted>
                        <h3>Defend Yourself</h3>
                      </Muted> <br />
                      <Button color="danger" disabled>Demo Unavailable</Button>
                    </CardHeader>
                    <CardBody plain>

                      <h5>
                        A tower defence game. Missile are starting to rain down on you. Can you survive this onslaught?
                      </h5>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </Row>
          </Container>


        </div>
      </div>
    </>
  );
}

export default Games;
